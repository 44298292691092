import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import debounce from 'lodash/debounce'

// Base Page
import BasePage from '@/pages/Base'

// Services
import CountryService from '@/services/Country'
import HscodesService from '@/services/Hscodes'
import Rules from '@/services/Rules'
import Categories from '@/services/Categories'
// import filter = require('lodash/filter');

@Component({})
export default class HscodesIndex extends BasePage {
  constructor() {
    super()
  }

  lastDescending: boolean = false
  lastSortBy: any = ''

  // Countries
  getCountriesLoading: boolean = false
  countries: any = []

  //category rule
  tableCategoryLoading: boolean = false
  categoryKeywords: any = null
  searchCategoryDebounce: Function
  itemDescripCategory: any = []
  // Add New
  addNewHscodes: boolean = false
  editHscodes: boolean = false
  newData: any = {
    selectedId: '',
    country: {
      selected: null,
      keyword: null
    },
    nativeLanguage: {
      selected: null,
      keyword: null
    },
    type: {
      items: [{ text: 'Product Rules', value: '1' }, { text: 'Category Rules', value: '2' }],
      selected: null
    },
    hscode: '',
    description: '',
    nativeDescription: '',
    descriptionCategory: '',
    taxes: {
      bm: 0,
      ppn: 0,
      pph: 0
    }
  }

  //delete
  selectedHscode: any = null
  showDeleteModal: boolean = false

  //update
  selectedEditId: string | number = null

  // Products Rule Table
  productRuleTable: any = {
    tableHeaders: [
      {
        text: 'ID',
        align: 'left',
        sortable: false,
        class: 'table-cell-width'
      },
      {
        text: 'Rules Name',
        align: 'left',
        sortable: false,
        class: 'table-cell-width'
      },
      {
        text: 'Rules Type',
        align: 'left',
        sortable: false,
        class: 'table-cell-width'
      }
    ]
  }

  // Filter Country
  filter: any = {
    selected: null,
    keyword: null
  }

  // Rules Table
  showRulesTable: boolean = false
  tableHeaders: object[] = [
    {
      text: 'ID',
      align: 'left',
      sortable: false
    },
    // {
    //   text: 'Country',
    //   align: 'left',
    //   sortable: false
    // },
    // {
    //   text: 'Type',
    //   align: 'left',
    //   sortable: false
    // },
    {
      text: 'Description',
      align: 'left',
      sortable: true,
      value: 'description'
    },
    {
      text: 'Native Description',
      align: 'left',
      sortable: false
    },
    {
      text: 'Native Language',
      align: 'left',
      sortable: false
    },
    {
      text: 'HS Code',
      align: 'left',
      sortable: true,
      value: 'code'
    },
    {
      text: 'Taxes',
      align: 'center',
      sortable: false
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: number | string = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    // sortBy: 'created_at',
    sortBy: 'description',
    page: 1,
    rowsPerPage: 10,
    descending: false,
    totalItems: 0
  }
  tableLoading: boolean = false
  tempDeleteCategory: any = []

  searchInput: string = ''
  searchInputDescription: string = ''

  async mounted() {
    await this.getCountries()
    this.getCategoryRule()
    this.filter.selected = find(this.countries, country => country.id === 99) // Set Default To Indonesia
    this.searchCategoryDebounce = debounce(this.getCategoryRule, 500)
  }
  @Watch('categoryKeywords')
  onCurrencyChanged() {
    this.searchCategoryDebounce()
  }
  @Watch('tablePagination', { deep: true })
  @Watch('filter.selected')
  @Watch('searchInputDescription')
  @Watch('searchInput')
  async onFilterChanged() {
    this.$vuetify.goTo(0)
    await this.getCountryHscodes()
  }
  @Watch('newData.descriptionCategory')
  onDescriptionChange(val, oldval) {
    // this.tempDeleteCategory = []
    if(oldval) {
      const found = filter(oldval, element => !val.includes(element))
      if(found != undefined){
        this.tempDeleteCategory.push(found)
      }
    }
  }

  async getCountries() {
    this.getCountriesLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }

      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data

      this.countries = []
      for (const country of responseData) {
        this.countries.push(country.attributes)
      }
      this.countries = sortBy(this.countries, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCountriesLoading = true
    }
  }

  // sini
  async getCountryHscodes() {
    try {
      this.tableLoading = true
      if (!isEmpty(this.filter.selected)) {

        if(this.tablePagination.descending === null && this.tablePagination.sortBy === null) {
          this.tablePagination.descending = !this.lastDescending
          this.tablePagination.sortBy = this.lastSortBy
        } else {
          this.lastDescending = this.tablePagination.descending
          this.lastSortBy = this.tablePagination.sortBy
        }

        // sini
        const opts: any = {
          params: {
            'page[num]': this.tablePagination.page,
            'page[limit]': this.tablePagination.rowsPerPage,
            sort: (this.tablePagination.descending ? '-' : '') + this.tablePagination.sortBy,
            // 'sort': this.tablePagination.sortBy,
            'filter[country_id][is]': this.filter.selected.id,
            include: 'productRules,categoryRules'
          }
        }

        if (this.searchInputDescription != '') {
          opts.params = {
            ...opts.params,
            'filter[description][like]': this.searchInputDescription
          }
        } else {
          var textFile = 'filter[description][like]'
          delete opts.params[textFile]
        }

        if (this.searchInput != '') {
          opts.params = {
            ...opts.params,
            'filter[code][like]': this.searchInput
          }
        } else {
          var textFile = 'filter[code][like]'
          delete opts.params[textFile]
        }

        const response = await HscodesService.getHscodes(opts)
        const responseData = response.data.data
        const responseMeta = response.data.meta
        const responseInclude = response.data.included
        this.tableItems = []
        for (const dataHscode of responseData) {
          const hscode = dataHscode.attributes
          hscode.actionFab = false
          // hscode.typeText = dataHscode.type === 1 ? 'Product Rules' : 'Category Rules'

          // Native Language
          const language = find(this.countries, country => country.iso_code_2 === dataHscode.attributes.native_language)
          hscode.nativeLanguageText = isEmpty(language) ? dataHscode.attributes.native_language : language.name

          // Generate Taxes
          hscode.bm = find(dataHscode.attributes.taxes, tax => lowerCase(tax.label) === 'bm')
          hscode.ppn = find(dataHscode.attributes.taxes, tax => lowerCase(tax.label) === 'ppn')
          hscode.pph = find(dataHscode.attributes.taxes, tax => lowerCase(tax.label) === 'pph')

          // Validasi data dummy
          if (hscode.bm !== undefined && hscode.ppn !== undefined && hscode.pph !== undefined) {
            hscode.taxesText =
              `
                <b>BM: </b>${hscode.bm.value}% ,
                <b>PPN: </b>${hscode.ppn.value}% ,
                <b>PPH: </b>${hscode.pph.value}% <br/>
                <b>Total Tax: </b>${hscode.bm.value + hscode.ppn.value + hscode.pph.value}%
              `
          }
          // Get Rules per Hscode
          hscode.theRules = []
          hscode.idCategory = []
          hscode.idProduct = []
          hscode.description_category = []
          if (dataHscode.relationships.productRules.length > 0) {
            forEach(dataHscode.relationships.productRules, dataProductRule => {
              // if (Number(responseData.relationships.productRules.id) === dataProductRule.attributes.id) {
              var tempProduct = responseInclude.productRules[dataProductRule.id]
              let productRules = {
                ...tempProduct.attributes,
                type: 'Product Rule',
                type_rule: tempProduct.type
              }
              hscode.theRules.push(productRules)
              hscode.idProduct.push(tempProduct.attributes.id)
              // }
            })
          }
          if (dataHscode.relationships.categoryRules.length > 0) {
            forEach(dataHscode.relationships.categoryRules, dataCategoryRule => {
              // if (Number(dataHscode.relationships.categoryRules[0].id) === dataCategoryRule.attributes.id) {
              var tempCategory = responseInclude.categoryRules[dataCategoryRule.id]
              let categoryRules = {
                ...tempCategory.attributes,
                type: 'Category Rule',
                type_rule: tempCategory.type
              }
              hscode.theRules.push(categoryRules)
              hscode.description_category.push(tempCategory.attributes.description)
              hscode.idCategory.push(tempCategory.attributes.id)
              // }
            })
          }
          this.tableItems.push(hscode)
        }
        this.tableTotalItems = responseMeta.pagination.total
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async getCategoryRule(){
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'filter[description][like]': ''
        }
      }
      if (!isEmpty(this.categoryKeywords)) {
        var textFilter = 'filter[description][like]'
        opts.params[textFilter] = this.categoryKeywords
      } else {
        var textFilter = 'filter[description][like]'
        delete opts.params[textFilter]
      }
      this.tableCategoryLoading = true
      const responseCategoryRules = await Rules.getCategoryRules(opts)
      const responseData = responseCategoryRules.data.data
      this.itemDescripCategory = []
      //get category rule
      for (const category of responseData) {
        if(category.attributes.description !== ''){
          this.itemDescripCategory.push({
            description: category.attributes.description,
            id: category.attributes.id
          })
        }
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableCategoryLoading = false
    }
  }

  async saveAddHscode() {
    try {
      const valid = await this.$validator.validateAll('addNewHscodesScope')
      if (valid) {
        const editMode = {
          state: this.editHscodes,
          id: this.newData.selectedId
        }
        var relationships = { rule: [] }
        forEach(this.newData.idProduct, rules => {
          const type_rule = {
            action: 'attach',
            id: rules.id,
            type: 'product_rule'
          }
          relationships.rule.push(type_rule)
        })
        
        forEach(this.newData.descriptionCategory, categories => {
          const category_rule = {
            action: 'attach',
            id: categories,
            type: 'category_rule'
          }
          relationships.rule.push(category_rule)
        })
        // if (!isEmpty(this.tempDeleteCategory)){
        forEach(this.tempDeleteCategory, categories => {
          const category_rule = {
            action: 'detach',
            id: categories,
            type: 'category_rule'
          }
          relationships.rule.push(category_rule)
        })
        // }
        var payload = {}
        if (editMode.state) {
          //edit
          payload = {
            // country_name: this.newData.country.selected.name,
            country_id: this.newData.country.selected.id,
            description: this.newData.description,
            native_description: this.newData.nativeDescription,
            native_language: this.newData.nativeLanguage.selected.iso_code_2,
            code: this.newData.hscode,
            // idCategory: this.newData.descriptionCategory,
            taxes: [
              { label: 'bm', value: this.newData.taxes.bm },
              { label: 'ppn', value: this.newData.taxes.ppn },
              { label: 'pph', value: this.newData.taxes.pph }
            ],
            total_tax: Number(this.newData.taxes.bm) + Number(this.newData.taxes.ppn) + Number(this.newData.taxes.pph),
            relationships: relationships
            // description_category: this.newData.descriptionCategory
          }
        } else {
          //add
          payload = {
            country_name: this.newData.country.selected.name,
            country_id: this.newData.country.selected.id,
            // type: this.newData.type.selected,
            description: this.newData.description,
            native_description: this.newData.nativeDescription,
            native_language: this.newData.nativeLanguage.selected.iso_code_2,
            code: this.newData.hscode,
            taxes: [
              { label: 'bm', value: this.newData.taxes.bm },
              { label: 'ppn', value: this.newData.taxes.ppn },
              { label: 'pph', value: this.newData.taxes.pph }
            ],
            total_tax: Number(this.newData.taxes.bm) + Number(this.newData.taxes.ppn) + Number(this.newData.taxes.pph),
            // idCategory: this.newData.descriptionCategory,
            relationships: relationships
          }
        }
        this.showLoading({ text: 'Saving...' })
        this.cancelModal() // Refresh data to initial value
        const response = await HscodesService.addHsCodes(payload, editMode.state ? editMode.id : '')
        this.tempDeleteCategory = []
        this.showSnackbar({
          text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
          color: response.data.success ? 'green' : 'red',
          timeout: 1500
        })
        await this.getCountryHscodes()
      } else {
        this.showSnackbar({ text: 'Please check all fields requirements', color: 'red', timeout: 1500 })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  openEditModal(props) {
    this.newData.selectedId = props.item.id
    this.newData.country.selected = find(this.countries, country => country.id === props.item.country_id)
    this.newData.nativeLanguage.selected = find(
      this.countries,
      country => country.iso_code_2 === props.item.native_language
    )
    forEach(props.item.idCategory, (id_category, index) => {
      this.itemDescripCategory.push({
        description: props.item.description_category[index],
        id: id_category
      })
    })
    
    this.newData.descriptionCategory = props.item.idCategory
    this.newData.type.selected = props.item.type
    this.newData.hscode = props.item.code
    this.newData.description = props.item.description
    this.newData.nativeDescription = props.item.native_description
    this.newData.taxes.bm = props.item.bm ? props.item.bm.value : 0
    this.newData.taxes.ppn = props.item.ppn ? props.item.ppn.value : 0
    this.newData.taxes.pph = props.item.pph ? props.item.pph.value : 0
    this.newData.theRules = props.item.theRules 
    this.addNewHscodes = true
    this.editHscodes = true
    this.selectedEditId = props.item.id
  }

  cancelModal() {
    this.$validator.reset()
    this.newData = {
      selectedId: '',
      country: {
        selected: null,
        keyword: null
      },
      nativeLanguage: {
        selected: null,
        keyword: null
      },
      type: {
        items: [{ text: 'Product Rules', value: 1 }, { text: 'Category Rules', value: 2 }],
        selected: null
      },
      hscode: '',
      description: '',
      nativeDescription: '',
      descriptionCategory: '',
      taxes: {
        bm: 0,
        ppn: 0,
        pph: 0
      }
    }
    this.editHscodes = false
    this.addNewHscodes = false
  }
  
  openDeleteModal(props) {
    this.selectedHscode = props.item
    this.showDeleteModal = true
  }

  async deleteHscode() {
    try {
      const payload = {
        id: [this.selectedHscode.id],
        action: 'delete'
      }
      this.showLoading({ text: 'Saving...' })
      this.showDeleteModal = false
      const response = await HscodesService.deleteHscode(this.selectedHscode.id)
      this.showSnackbar({
        text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
        color: response.status === 200 ? 'green' : 'red',
        timeout: 1500
      })
      this.getCountryHscodes()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }
}
